define(['app', 'elementViewed'], (app, elementViewed) => {

  const subscriptionComponent = () => {
    const component = {};

    const keys = {
      left: "ArrowLeft",
      ieLeft: "Left",
      right: "ArrowRight",
      ieRight: "Right",
      end: "End",
      home: "Home",
      tab: "Tab"
    };

    const _config = {
      radioSelector: '[data-radio]',
      tabLinks: '[data-js-element=subscriptionComponent_tab-link]',
      tabPanels: '[data-js-element=subscriptionComponent_tab-panel]',
      tabLinkActive: 'subscriptionComponent_tabTitle-active',
      tabPanelActive: 'subscriptionComponent_tabPanel-active',

      subscribeChannels: {
        subscriptionTerm: 'subscriptionComponent/subscriptionTerm',
        subscriptionType: 'subscriptionComponent/subscriptionType'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.loaded = false;
      component.bind();
      component.onLoadShowRequiredPanel();
      return component;
    };

    const _bind = () => {
      let tabLinks = component.element.querySelectorAll(component.config.tabLinks);
      component.numberOfTabs = tabLinks.length;
      component.attachEvent(tabLinks);
    };

    const _onLoadShowRequiredPanel = () => {
      let radioButtons = component.element.querySelectorAll(component.config.radioSelector);
      [...radioButtons].forEach((radio) => {
        if (radio.checked) {
          const subscriptionType = radio.getAttribute('data-card-subscription-type');
          const termOption = radio.getAttribute('data-card-subscription-term');
          app.publish(component.config.subscribeChannels.subscriptionTerm, termOption, true);
          app.publish(component.config.subscribeChannels.subscriptionType, subscriptionType, true);
          let selectedTabIndex = (subscriptionType === 'recurring') ? 1 : 0;
          component.tabLinkClicked(selectedTabIndex);
        }
      });
      component.loaded = true;
    };

    const _attachEvent = (tabLinks) => {
      for (let i = 0; i < tabLinks.length; i++) {
        tabLinks[i].addEventListener('click', () => { component.tabLinkClicked(i) });
        tabLinks[i].addEventListener("keydown", event => {component.keyChangeTab(event, i);});

      }
    };

    const _tabLinkClicked = (i) => {
      component.removeActiveClass();
      component.showActiveTab(i);
    };

    const _removeActiveClass = () => {
      let tabLinks = component.element.querySelectorAll(component.config.tabLinks);
      let tabPanels = component.element.querySelectorAll(component.config.tabPanels);
      [...tabLinks].forEach((element) => {
        app.element.removeClass(component.config.tabLinkActive, element);
        app.element.setAttribute('aria-selected', false, element);
        app.element.setAttribute('tabindex', -1, element);

      });
      [...tabPanels].forEach((element) => {
        app.element.removeClass(component.config.tabPanelActive, element);
        app.element.setAttribute('aria-hidden', true, element);
      });
    };

    const _showActiveTab = (i) => {
      const selectedTabLink = component.element.querySelectorAll(component.config.tabLinks)[i];
      const selectedTabPanel = component.element.querySelectorAll(component.config.tabPanels)[i];
      app.element.addClass(component.config.tabLinkActive, selectedTabLink);
      app.element.addClass(component.config.tabPanelActive, selectedTabPanel);
      app.element.setAttribute('aria-selected', true, selectedTabLink);
      app.element.removeAttribute('tabindex', selectedTabLink);
      app.element.setAttribute('aria-hidden', false, selectedTabPanel);
      component.loaded && selectedTabLink.focus();
    };

    const _keyChangeTab = (event, i) => {
      let nextTab;
      const key = event.key;

      switch (key) {
        case keys.ieLeft:
        case keys.left:
          nextTab = i - 1;
          i > 0 && component.tabLinkClicked(nextTab);
          event.preventDefault();
          break;

        case keys.ieRight:
        case keys.right:
          nextTab = i + 1;
          nextTab < component.numberOfTabs && component.tabLinkClicked(nextTab);
          event.preventDefault();
          break;

        case keys.home:
          component.tabLinkClicked(0);
          event.preventDefault();
          break;

        case keys.end:
          component.tabLinkClicked(component.numberOfTabs - 1);
          event.preventDefault();
          break;
      }
    };


    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.attachEvent = _attachEvent;
    component.tabLinkClicked = _tabLinkClicked;
    component.showActiveTab = _showActiveTab;
    component.removeActiveClass = _removeActiveClass;
    component.onLoadShowRequiredPanel = _onLoadShowRequiredPanel;
    component.keyChangeTab = _keyChangeTab;

    return component;
  };

  return subscriptionComponent;
});
